.hamburger {
    width: 28px;
    height: 20px;
    
    cursor: pointer;
}

.menu.icon {
    color: var(--color-secondary);
    position: relative;

    top: 50%;

    width: 28px;
    height: 3px;
    background-color: currentColor;
}

.menu.icon:before {
    content: '';
    position: absolute;
    top: -9px;
    left: 0;
    width: 28px;
    height: 3px;
    background-color: currentColor;
}

.menu.icon:after {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 28px;
    height: 3px;
    background-color: currentColor;
}