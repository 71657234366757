.explanation {
    text-align: -webkit-center;
    font-family: "Terfens", sans-serif;
}

.explanationContainer {
    display: flex;
    flex-direction: column;
    
    /* gap: 48px; */

    text-align: left;

    max-width: var(--max-page-width);

    line-height: var(--line-height);

    margin: 36px 48px;
}

.explanationBanner .banner {
    width: 100%;
    height: 70vh;

    background: url("../../Media/explanation/explanation1.jpg");
    background-size: cover;
    background-position: 50%;
}

.explanationContainer p:not(.main) {
    font-size: var(--font-size-x-small);
}

.explanation .main {
    margin-bottom: 36px;
}

@media only screen and (max-width: 850px) {
    .explanationBanner .banner {
        height: 35vh;
    }
}
