.home {
    text-align: -webkit-center;
    font-family: "Terfens", sans-serif;
}

.homeContainer {
    display: flex;
    flex-direction: column;
    
    gap: 24px;

    max-width: var(--max-page-width);

    line-height: var(--line-height);

    margin: 36px 48px;
}

.homeBanner .banner {
    width: 100%;
    height: 70vh;

    background: url("../../Media/home/home1.jpg");
    background-size: cover;
    background-position: 50%;
}

.homeExplanation {
    text-align: left;
}

.homeExplanation > * {
    font-size: var(--font-size-large);
}


.homeExplanation p span {
    font-weight: bold;
    color: var(--color-secondary);

    line-height: 3;
}

.homeNote1 > * {
    font-size: var(--font-size-medium);
}

.homeQuote p {
    font-size: var(--font-size-medium);
}

.homeQuote div {
    display: inline-flex;

    flex-direction: column;
}

.homeQuote div > :first-child {
    margin-bottom: 0;
}

.homeQuote div > :last-child {
    text-align: right;
    font-size: var(--font-size-small);
}

.homeNote1 {
    display: flex; 

    flex-direction: column;
}

.homeNote1 .row {
    display: flex;
    flex: 1;

    flex-direction: row;
}

.homeNote1 .row > :first-child {
    text-align: left;
}

.homeNote1 .row > :last-child {
    text-align: right;
}

.homeNote1 .column {
    flex: 1;
}

.homeNote2 > * {
    font-size: var(--font-size-medium);
}

@media only screen and (max-width: 850px) {
    .homeBanner .banner {
        height: 35vh;
    }
}