.headerContainer {
    text-align: -webkit-center;
}

.header {
    max-width: var(--max-page-width);

    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 24px 48px 12px 48px;
}

.logoContainer {
    display: flex;
}

.logoLink {
    height: 125px;

    z-index: 100;
}

.logo {
    height: 100%;
}

.nav-link-container {
    display: flex;
    flex: 1;

    justify-content: flex-end;
	align-items: center;

    column-gap: 48px;
}

.navLink {
    font-size: var(--font-size-small);
    font-family: "Terfens", sans-serif;
    font-weight: bold;

    line-height: 2;

    color: var(--color-secondary);
    text-decoration: none;

    z-index: 100;
}

.navLink:hover {
    text-decoration: underline;
}

.hamburgerContainer {
    display: none;

    width: 28px;
    height: 20px;

    z-index: 300;

    text-align: right;
}

@media only screen and (max-width: 1000px) {
    .nav-link-container {
        display: none
    }

    .hamburgerContainer {
        display: inline-block;
    }

    .hamburgerContainer.open {
        display: none;
    }
}

.sideDrawerNav {
    display: flex;
    position: fixed;
    
    top: 0; 
    right: 0;

    height: 100%;

    flex-direction: column;
    justify-content: center;

    background-color: var(--color-secondary);

    z-index: 200;

    transform: translateX(200%);
    transition: transform 0.2s ease-out;

    row-gap: 24px;

    padding: 0px 48px;
}

.sideDrawerNavLink {
    font-size: var(--font-size-small);
    font-family: "Terfens", sans-serif;

    line-height: 2;

    color: var(--color-light);
    text-decoration: none;

    z-index: 100;
}

.sideDrawerNavLink:hover {
    text-decoration: underline;
}

.sideDrawerNav.open {
    transform: translateX(0%);
}

.backDrop {
    display: none;

    position: absolute;

    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    z-index: 100;

    background-color: rgba(255,255,255, 0);
    backdrop-filter: blur(3px);
}

.backDrop.open {
    display: block;
}