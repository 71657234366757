.services {
    text-align: -webkit-center;
    font-family: "Terfens", sans-serif;
}

.servicesContainer {
    display: flex;
    flex-direction: column;

    max-width: var(--max-page-width);

    line-height: var(--line-height);

    margin: 36px 48px;

    text-align: left;
}

.servicesImg {
    width: 100%;
    aspect-ratio: auto;

    margin-bottom: 36px;
}

.priceContainer table {
    color: var(--color-dark);
}

.priceContainer table td {
    vertical-align: top;
}

.priceContainer tr > :first-child {
    padding-right: 48px;
}
