.contact {
    display: flex;
    flex-direction: column;

    gap: 20px;

    max-width: 800px;

    margin: 24px auto 0 auto;
    padding: 0px 48px;

    font-family: "Terfens", sans-serif;
}

.contactDescription {
    font-size: var(--font-size-small);
    color: var(--color-dark);

    line-height: var(--line-height);

    text-align: start;

    margin: 0;
    margin-bottom: 12px;
}

.contactForm {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.contactLabel {
    display: block;

    text-align: start;

    margin-bottom: 5px;

    font-size: var(--font-size-small);
    color: var(--color-dark);

    font-weight: bold;
}

.contactInput {
    width: 100%;

    font-family: "Terfens", sans-serif;
    font-size: var(--font-size-small);

    border: 1px solid var(--color-dark);
    border-radius: 5px;

    padding: 10px;

    box-sizing: border-box;
}

.contactTextarea {
    resize: none;

    height: auto;

    font-family: "Terfens", sans-serif;
}

.buttonSendMessage {
    width: max-content;

    background-color: var(--color-secondary);
    color: var(--color-light);

    padding: 10px 15px;

    border: none;
    border-radius: 5px;

    font-family: "Terfens", sans-serif;
    font-size: var(--font-size-small);

    cursor: pointer;
}