/* @import url(//db.onlinewebfonts.com/c/e68a64ce54339a0bcee33f4597af9454?family=Terfens); */
@font-face {
    font-family: "Terfens";
    src: url("./fonts/Terfens\ W01\ Regular.eot");
    src: url("./fonts/Terfens\ W01\ Regular.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Terfens\ W01\ Regular.woff") format("woff"),
        url("./fonts/Terfens\ W01\ Regular.woff2") format("woff2"),
        url("./fonts/Terfens\ W01\ Regular.ttf") format("truetype"),
        url("./fonts/Terfens\ W01\ Regular.svg#Terfens") format("svg");
}

:root {
    --color-primary: #9ea861;
    --color-secondary: #7299a0;
    --color-dark: #403b36;
    --color-light: white;

    --font-size-x-small: 14px;
    --font-size-small: 16px;
    --font-size-medium: 20px;
    --font-size-large: 24px;

    --line-height: 2;

    --max-page-width: 1000px;
}

html {
    background-color: var(--color-light);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Terfens',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: "Terfens", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h2 {
    font-size: var(--font-size-medium);
    font-weight: bold;

    color: var(--color-dark);
}

h3 {
    font-size: var(--font-size-small);
    font-weight: bold;

    color: var(--color-dark);
}

h4 {
    font-size: var(--font-size-x-small);
    font-weight: bold;

    text-decoration: underline;

    color: var(--color-dark);
}

p {
    color: var(--color-dark);
}

ul {
    display: inline-flex;
    flex-direction: column;

    text-align: left;
}

ul li {
    padding-left: 10px;
    list-style-type: unset;
    color: var(--color-dark);
}

ul li:not(:last-child) {
    margin-bottom: 15px;
}

.bold {
    font-weight: bold;
}