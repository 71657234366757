.imprint {
    text-align: -webkit-center;
    font-family: "Terfens", sans-serif;
}

.imprint-container {
    display: flex;
    flex-direction: column;

    max-width: var(--max-page-width);

    line-height: var(--line-height);

    margin: 36px 48px;

    text-align: left;
}

.imprint-container a {
    color: var(--color-dark);
}