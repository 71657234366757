.footer {
    margin: 0 auto;
    margin-top: 48px;
    padding: 36px 48px 48px 48px;

    background-color: var(--color-secondary);

    line-height: 1.75;
}

.footer h4 {
    color: var(--color-light);

    line-height: 2.5;

    text-decoration: none;

    margin: 0;
}

.contactData {
    font-size: 14px;

    color: var(--color-light);

    margin: 0;
}

.contactDataLink {
    color: var(--color-light);

    text-decoration: underline;
}