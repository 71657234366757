.community-nursing {
    text-align: -webkit-center;
    font-family: "Terfens", sans-serif;
}

.community-nursing-container {
    display: flex;
    flex-direction: column;
    
    /* gap: 48px; */

    text-align: left;

    max-width: var(--max-page-width);

    line-height: var(--line-height);

    margin: 36px 48px;
}


.community-nursing-container ul {
	margin-top: 4px;
}

.community-nursing-container h3 {
	text-decoration: underline;
}
