.about {
    text-align: -webkit-center;
    font-family: "Terfens", sans-serif;
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    
    gap: 24px;

    max-width: var(--max-page-width);

    line-height: var(--line-height);

    margin: 36px 48px;
}

.aboutPictureContainer {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 48px;
}

.aboutPictureContainer div {
    flex: 1;
}

.aboutPictureContainer div img {
    max-height: 450px;
}

.aboutPictureContainer div p {
    text-align: left;

    font-size: var(--font-size-small);
}

@media only screen and (max-width: 850px) {
    .aboutPictureContainer {
        flex-direction: column;
    }

    .aboutPictureContainer div img {
        max-width: 100%;
        aspect-ratio: auto;
    }
}

.aboutTextContainer p {
    text-align: left;
}

.aboutTextContainer > :last-child {
    font-size: var(--font-size-medium);
    font-weight: bold;
    
    text-align: center;

    color: var(--color-secondary);
}

.aboutListContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.aboutListContainer ul {
    max-width: 500px;
}

.videoContainer {
    margin-top: 36px;
}

.videoContainer iframe {
    width: 100%;
    aspect-ratio: 16/9;
}
